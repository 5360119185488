import {Token as BaseToken} from '@nuxtjs/auth-next/dist/runtime';
import jwtDecode from 'jwt-decode';

function addTokenPrefix(token, tokenType) {
  if (!token || !tokenType || typeof token !== 'string' || token.startsWith(tokenType)) {
    return token;
  }
  return tokenType + ' ' + token;
}

export default class Token extends BaseToken {
  set(tokenValue) {
    const token = addTokenPrefix(tokenValue, this.scheme.options.token.type);
    this._setToken(token);
    this._updateExpiration(token);
    return token;
  }

  _setToken(token) {
    const _key = this.scheme.options.token.prefix + this.scheme.name;
    return this.$storage.setUniversal(_key, token);
  }

  _setExpiration(expiration) {
    const _key = this.scheme.options.token.expirationPrefix + this.scheme.name;
    return this.$storage.setUniversal(_key, expiration);
  }

  _updateExpiration(token) {
    let tokenExpiration;
    const _tokenIssuedAtMillis = Date.now();
    const _tokenTTLMillis = Number(this.scheme.options.token.maxAge) * 1e3;
    const _tokenExpiresAtMillis = _tokenTTLMillis ? _tokenIssuedAtMillis + _tokenTTLMillis : 0;
    try {
      tokenExpiration = jwtDecode(token + '').exp * 1e3 || _tokenExpiresAtMillis;
    } catch (error) {
      tokenExpiration = _tokenExpiresAtMillis;
      if (!(error && error.name === 'InvalidTokenError')) {
        throw error;
      }
    }
    return this._setExpiration(tokenExpiration || false);
  }
};
