
export default {
  props: {
    context: {
      type: Object,
      required: true,
    },
    now: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    date: null,
    modelConfig: {
      mask: 'DD-MM-YYYY',
    },
    attrs: [
      {
        key: 'today',
        highlight: {
          class: 'today',
        },
        dates: new Date(),
      },
    ],
  }),
  computed: {
    calendarAttributes() {
      return Object.assign(this.context.attributes, {id: null, class: null});
    },
    isDisabled() {
      return 'disabled' in this.context.attributes;
    },
  },
  watch: {
    date(newDate) {
      this.context.model = this.$moment(newDate).format('YYYY-MM-DD');
    },
    'context.model'() {
      if (!this.date) {
        this.date = this.context.model;
      }
    },
  },
  mounted() {
    this.date = this.now ? new Date() : null;
  },
};
